import logo from './logo.svg';
import './App.css';
import Dashbaord from './screens/Dashboard';

function App() {
  return (
    <div>
      <header>
        <Dashbaord />
      </header>
    </div>
  );
}

export default App;
